
// @ts-nocheck
import Vue from "vue";

import RegisterInfoCompaniesHouse from "@/components/RegisterInfo/CompaniesHouse.vue";
import RegisterInfoOther from "@/components/RegisterInfo/Other.vue";

export default Vue.extend({
  components: {
    RegisterInfoCompaniesHouse,
    RegisterInfoOther,
  },
  props: {
    registerResult: Object,
  },
  data() {
    return {
      companiesHouseDataError: false,
    };
  },
  computed: {
    registerOther() {
      const registers = this.registerResult?.registers;
      // if the companies house endpoint failed, show the simple version in the
      // other register info list
      return this.companiesHouseDataError
        ? registers
        : registers?.filter((r) => r.body !== "Companies House");
    },
    registerCompaniesHouse() {
      return this.registerResult?.registers?.filter(
        (r) => r.body === "Companies House",
      )?.[0];
    },
  },
});
