
// @ts-nocheck
import { Card, SkeletonLine } from "@num/component-library";
import axios, { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "RegisterInfoCompaniesHouse",
  components: {
    Card,
    SkeletonLine,
  },
  props: {
    registerCompaniesHouse: Object,
  },
  data() {
    return {
      companiesHouseInitialLinkFailed: false,
      loading: true,
      data: null,
    };
  },
  computed: {
    companyNumber() {
      const companiesHouseId = this.registerCompaniesHouse.id;
      if (!companiesHouseId) return null;
      return this.companiesHouseInitialLinkFailed
        ? companiesHouseId.padStart(8, "0")
        : companiesHouseId;
    },
    registeredAddress() {
      const address = this.data?.RegAddress;
      if (!address) return null;
      return [
        address.AddressLine1,
        address.AddressLine2,
        address.AddressLine3,
        address.AddressLine4,
        address.AddressLine5,
        address.PostTown,
        address.Postcode,
      ]
        .filter((l) => !!l)
        .join(",<br />");
    },
    sicCodes() {
      return this.data?.SICCodes?.SicText.map((c) => {
        const [code, description] = c.split(/-(.+)/);
        return {
          code: code.trim(),
          description: description.trim(),
        };
      });
    },
    viewLink() {
      return `https://find-and-update.company-information.service.gov.uk/company/${this.companyNumber}`;
    },
  },
  created() {
    if (!this.registerCompaniesHouse?.www) return;

    this.getCompaniesHouseInfo();
  },
  methods: {
    formatDate(companiesHouseDate) {
      const formattedDate = companiesHouseDate.split("/").reverse().join("-");
      return moment(formattedDate).format("Do MMMM YYYY");
    },
    getCompaniesHouseInfo() {
      const adjustedCompaniesHouseLink =
        this.registerCompaniesHouse.www.replace(
          this.registerCompaniesHouse.id,
          this.companyNumber,
        );

      axios
        .get(adjustedCompaniesHouseLink)
        .then(async (successfulResponse: AxiosResponse) => {
          this.data = successfulResponse?.data?.primaryTopic;
          this.loading = false;

          return successfulResponse;
        })
        .catch((error: AxiosError) => {
          console.error(error);

          // if we've already failed, then give up now
          if (this.companiesHouseInitialLinkFailed) {
            this.loading = false;
            this.$emit("data-error");
          } else {
            // if we've not yet failed, then try again with padded zeroes
            this.companiesHouseInitialLinkFailed = true;
            this.getCompaniesHouseInfo();
          }

          return error.response;
        });
    },
  },
});
