
// @ts-nocheck
import { SkeletonLine, utilsTypeOf } from "@num/component-library";
import Vue from "vue";

const { isEmptyObject } = utilsTypeOf;

export default Vue.extend({
  name: "CompanyLogo",
  components: {
    SkeletonLine,
  },
  props: {
    companyLogo: String,
    companyResult: Object,
    imageResult: Object,
    loading: Boolean,
  },
  data() {
    return {
      fallbackImage: "/img/fallback/office.png",
      imageIsDark: null,
      imageContainsTransparency: null,
      isEmptyObject,
    };
  },
  watch: {
    companyLogo: {
      handler(logo) {
        if (logo) this.handleImage(logo);
      },
      immediate: true,
    },
  },
  methods: {
    handleImage(logo) {
      const img = document.createElement("img");
      img.src = logo;
      img.style.display = "none";
      img.crossOrigin = "anonymous";
      document.body.appendChild(img);

      img.onload = () => {
        const { nonTransparentBrightness, transparency } =
          this.getImageBrightness(img);

        this.imageIsDark = nonTransparentBrightness <= 60;
        this.imageContainsTransparency = transparency > 0;
      };
    },
    getImageBrightness(img) {
      // src: https://stackoverflow.com/a/70744412/827129
      if (!img) return null;
      let alphaSum = 0;
      let colorSum = 0;
      var canvas = document.createElement("canvas");

      // make the canvas use the full size of the image, not the rendered size
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      var data = imageData.data;
      var r, g, b, a, avg;

      for (var x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];
        a = data[x + 3];

        avg = Math.floor((r + g + b) / 3);
        colorSum += avg;
        alphaSum += a;
      }

      const transparency =
        100 -
        Math.floor(
          (alphaSum / (img.naturalWidth * img.naturalHeight) / 255) * 100,
        );
      const brightness = Math.floor(
        (colorSum / (img.naturalWidth * img.naturalHeight) / 255) * 100,
      );
      const nonTransparentBrightness = Math.floor(
        (brightness / (100 - transparency)) * 100,
      );

      return { brightness, transparency, nonTransparentBrightness };
    },
  },
});
