
// @ts-nocheck
import Vue from "vue";

import { Input } from "@num/component-library";

import CompanyResultAddresses from "@/components/CompanyResult/Addresses.vue";
import CompanyResultSocialMedia from "@/components/CompanyResult/SocialMedia.vue";

import {
  getTelephoneCountFromLink,
  textIsPartialPhoneNumber,
} from "@/utils/utils";

export default Vue.extend({
  name: "CompanyResult",
  components: {
    CompanyResultAddresses,
    CompanyResultPhoneNumbers: () =>
      import("@/components/CompanyResult/PhoneNumbers.vue"),
    CompanyResultSocialMedia,
    Input,
  },
  props: {
    companyResult: Object,
  },
  data() {
    return {
      filterInput: "",
      filteredPhoneNumbers: null,
    };
  },
  computed: {
    addresses() {
      return this.companyResult?.contacts?.filter(
        (c) => c.method_type === "address",
      );
    },
    filterInputIsPhoneNumber() {
      // this just tests if the string entered contains only brackets, numbers,
      // dots, dashes or spaces
      return textIsPartialPhoneNumber(this.filterInput);
    },
    filteredTelephoneCount() {
      return this.getTelephoneCountFromContacts(this.filteredPhoneNumbers);
    },
    inputClass() {
      let classes = ["p-1"];
      if (this.filterInput !== "") classes.push("pr-7");
      return classes.join(" ");
    },
    socialMediaContacts() {
      return this.companyResult?.contacts?.filter((c) => !!c.controller);
    },
    telephoneCount() {
      return this.getTelephoneCountFromContacts(this.companyResult?.contacts);
    },
  },
  watch: {
    companyResult: {
      handler(newVal) {
        if (!newVal) return;
        this.filterContacts(newVal);
      },
      immediate: true,
    },
    filterInput() {
      this.filterContacts(this.companyResult);
    },
  },
  methods: {
    clearInput() {
      this.filterInput = "";
    },
    filterContacts(companyResult) {
      const { contacts } = companyResult;
      if (!contacts) return;
      // must copy the contents to stop the original being manipulated
      // src: https://stackoverflow.com/a/28482916/827129
      const contactsCopy = JSON.parse(JSON.stringify(contacts));
      this.filteredPhoneNumbers = this.handleContacts("", contactsCopy);
    },
    getTelephoneCountFromContacts(contacts) {
      let count = 0;

      if (contacts) {
        for (const contact of contacts) {
          try {
            if (contact.method_type === "link")
              count += getTelephoneCountFromLink(contact) || 0;
            else if (contact.method_type === "telephone") count += 1;
          } catch (error) {
            console.error(error);
          }
        }
      }

      return count;
    },
    handleContacts(searchLabel, contacts) {
      return contacts
        ?.map((c) => {
          return this.handleContactItem(searchLabel, c);
        })
        .filter((c) => c !== null);
    },
    handleContactItem(searchLabel, contact) {
      const { method_type } = contact;

      if (method_type === "link") {
        searchLabel += ` ${contact.description || contact["@L"]}`;

        if (contact?.numObject?.contacts) {
          contact.numObject.contacts = this.handleContacts(
            searchLabel,
            contact.numObject.contacts,
          );
        }

        return contact;
      } else if (method_type === "telephone") {
        const regexNonDigits = /\D+/g;
        const phoneNumberToUse =
          contact.value.display || contact.value.original || contact.value;
        const phoneNumberJustNumbers = phoneNumberToUse.replace(
          regexNonDigits,
          "",
        );

        if (contact.description)
          searchLabel += ` ${contact.description} ${phoneNumberJustNumbers}`;

        const filterInputJustNumbers = this.filterInput.replace(
          regexNonDigits,
          "",
        );
        const filterToUse = this.filterInputIsPhoneNumber
          ? filterInputJustNumbers
          : this.filterInput;

        const matchesFilter = this.searchWordsExistWithinTarget(
          filterToUse,
          searchLabel,
        );

        return matchesFilter ? contact : null;
      }

      return null;
    },
    searchWordsExistWithinTarget(searchTerms, target) {
      if (searchTerms === "") return true;
      const searchTermsArray = searchTerms.split(" ");
      const allWordsExist = searchTermsArray.every((s) => {
        return target.toLowerCase().indexOf(s.toLowerCase()) > -1;
      });
      return allWordsExist;
    },
  },
});
