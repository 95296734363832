
// @ts-nocheck
import { Card } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  name: "CompanyResultIsError",
  components: {
    Card,
  },
  props: {
    showError: Boolean,
    result: [Object, String],
  },
});
