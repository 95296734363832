import { createCompanyApi } from "company-api";
import { createIVRApi } from "ivr-api";
import { createClient, DoHResolver } from "num-client";

import store from "@/store";

import { MODE } from "@/app.config";

const CUSTOM_RESOLVERS = [
  new DoHResolver("Cloudflare", "https://cloudflare-dns.com/dns-query"),
  new DoHResolver("Google", "https://dns.google.com/resolve"),
  new DoHResolver("NUM", "https://api.apps.num.uk/v1/doh/dns-query"),
];

// export a method so we get the latest settings each time it's run
export const setupNumClient = (): any => {
  // Pass in an optional array of DoHResolvers now instead of a `dnsClient`
  const numClient = createClient(CUSTOM_RESOLVERS);

  numClient.disableSchemaValidation();
  MODE !== "ext" && numClient.setDnsEnv(store.state.settings.dnsEnvironment);
  MODE !== "ext" &&
    numClient.setModuleEnv(store.state.settings.modulesEnvironment);

  return numClient;
};

// export a method so we get the latest settings each time it's run
export const setupCompanyApi = (): any => {
  const numClient = setupNumClient();
  return createCompanyApi(numClient);
};

export const setupIvrApi = (): any => {
  const numClient = setupNumClient();
  return createIVRApi(numClient);
};
