
// @ts-nocheck
import { utilsTypeOf, Card, CompanyApiErrors } from "@num/component-library";
import { CompanyApiOptions } from "company-api";
import { parseNumUri, PositiveInteger } from "num-client";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

import CompanyHeader from "@/components/CompanyHeader.vue";
import CompanyResult from "@/components/CompanyResult.vue";
import CompanyResultIsError from "@/components/CompanyResult/IsError.vue";
import Container from "@/components/Container.vue";
import PwaCard from "@/components/PwaCard.vue";
import RegisterInfo from "@/components/RegisterInfo.vue";

import { setupCompanyApi, setupNumClient } from "@/companyApi";

import { Error, Image, Result } from "@/types/index";

import { SET_COMPANY_RESULT } from "@/store/types/mutations";

import { NUM_SERVER_URL } from "@/app.config.ts";

const { isEmptyObject } = utilsTypeOf;

export default Vue.extend({
  name: "CompanyView",
  components: {
    Card,
    CompanyApiErrors,
    CompanyHeader,
    CompanyResult,
    CompanyResultIsError,
    Container,
    PwaCard,
    RegisterInfo,
  },
  metaInfo() {
    // must assign reactive data to local var first
    // src: https://vue-meta.nuxtjs.org/guide/caveats.html#reactive-variables-in-template-functions
    const companyDomain = this.companyDomain;
    const companyName = this.companyName;
    return {
      title: `${companyName || companyDomain} Contact Information`,
    };
  },
  props: {
    companyDomain: String,
  },
  data() {
    return {
      isEmptyObject,
      contactInfoLoading: true,
      registerInfoLoading: true,
      numUriError: null,
      registerResult: null,
    };
  },
  computed: {
    ...mapGetters(["countryCode", "getCompanyResult"]),
    claimLink(): string {
      return (
        this.companyDomain && `${NUM_SERVER_URL}/claim/${this.companyDomain}`
      );
    },
    companyLogo(): string | undefined {
      return this.imageResult?.variants?.[0]?.url;
    },
    companyName(): string | undefined {
      return !this.companyResultIsError ? this.companyResult?.name : null;
    },
    companyResult(): Result | undefined {
      return this.getCompanyResult(this.companyDomain);
    },
    companyResultErrors(): Error[] | undefined {
      return this.companyResult?.metadata?.errors;
    },
    companyResultIsError(): boolean {
      return (
        this.companyResult instanceof TypeError ||
        this.companyResult instanceof window?.Error
      );
    },
    companyResultNoRecordsFound(): boolean {
      const noRecordsToShow =
        !this.companyResult?.contacts && !this.registerResult;
      const allErrorsAreNotFound = !!this.companyResultErrors?.every(
        (e) => e.error === "NUM_RECORD_NOT_FOUND",
      );
      return noRecordsToShow && allErrorsAreNotFound;
    },
    companySlogan(): string | undefined {
      return this.companyResult?.slogan;
    },
    imageResult(): Image | undefined {
      return this.companyResult?.images?.[0];
    },
    isPopulated(): boolean {
      return !!this.companyResult?.populated;
    },
  },
  created() {
    this.getContactInfo();
    this.getRegisterInfo();
  },
  methods: {
    ...mapMutations([SET_COMPANY_RESULT]),
    getContactInfo(): void {
      const companyApi = setupCompanyApi();
      const companyApiOptions = new CompanyApiOptions({
        country: this.countryCode,
      });

      companyApi
        .lookupDomain(this.companyDomain.trim(), companyApiOptions)
        .then((result) => {
          this.SET_COMPANY_RESULT({ domain: this.companyDomain, result });
          this.contactInfoLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.SET_COMPANY_RESULT({
            domain: this.companyDomain,
            result: error,
          });
          this.contactInfoLoading = false;
        });
    },
    handleAnyNumClientResponse(result) {
      this.registerInfoLoading = false;
      this.registerResult = result;
    },
    getRegisterInfo() {
      let numUri;
      this.registerResult = null;

      // validate NUM URI before continuing and throw error if fails
      try {
        numUri = parseNumUri(this.companyDomain);
        numUri = numUri.withPort(new PositiveInteger(5));
      } catch (error) {
        console.error(error);
        this.numUriError = error;
        this.registerInfoLoading = false;
        return;
      }

      this.registerInfoLoading = true;
      this.numUriError = null;

      const numClient = setupNumClient();
      const ctx = numClient.createContext(numUri);
      this.setModuleVersions(numUri, ctx);
      numClient
        .retrieveNumRecord(ctx)
        .then((result) => {
          this.handleAnyNumClientResponse(JSON.parse(result));
        })
        .catch((error) => {
          console.error(error);
          this.handleAnyNumClientResponse(error);
        });
    },
    setModuleVersions(numUri, ctx) {
      if (numUri.port.n === 1) ctx.setTargetExpandedSchemaVersion("2");
    },
  },
});
