
import { SkeletonLine } from "@num/component-library";
import Vue from "vue";

import CompanyDomain from "@/components/CompanyDomain.vue";
import CompanyHeaderSticky from "@/components/CompanyHeaderSticky.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import Container from "@/components/Container.vue";
import Hero from "@/components/Hero.vue";

import { MODE } from "@/app.config";

export default Vue.extend({
  name: "CompanyHeader",
  components: {
    CompanyDomain,
    CompanyHeaderSticky,
    CompanyLogo,
    Container,
    Hero,
    SkeletonLine,
  },
  props: {
    companyDomain: String,
    companyLogo: String,
    companyName: String,
    companyResult: [Object, Error],
    companySlogan: String,
    imageResult: Object,
    isPopulated: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      MODE,
    };
  },
});
