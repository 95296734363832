
// @ts-nocheck
import Vue from "vue";

import ResultCard from "@/components/ResultCard.vue";

export default Vue.extend({
  name: "CompanyResultAddresses",
  components: {
    ResultCard,
  },
  props: {
    addresses: Array,
  },
  computed: {
    addressesCount() {
      return this.addresses?.length;
    },
  },
  methods: {
    formatAddress(address) {
      const regionNamesInEnglish = new Intl.DisplayNames(["en"], {
        type: "region",
      });
      const addressLinesWithPostcodeAndCountry = [
        ...(address.lines ?? []),
        address.postcode,
        regionNamesInEnglish.of(address.country),
      ];
      return this.addressesCount > 1
        ? addressLinesWithPostcodeAndCountry.join(",<br />")
        : addressLinesWithPostcodeAndCountry.join(", <br class='sm:hidden' />");
    },
  },
});
