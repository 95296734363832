
// @ts-nocheck
import Vue from "vue";

import ResultCard from "@/components/ResultCard.vue";

export default Vue.extend({
  name: "CompanyResultSocialMedia",
  components: {
    ResultCard,
  },
  props: {
    socialMedia: Array,
  },
  computed: {
    socialMediaCount() {
      return this.socialMedia?.length;
    },
  },
});
