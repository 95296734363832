
// @ts-nocheck
import { Card } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  components: {
    Card,
  },
  props: {
    innerClass: [Object, String],
    item: Object,
    itemColor: String,
    itemTitle: String,
    itemTitleClass: [Object, String],
  },
  computed: {
    imgStyle() {
      return this.itemColor && `border: 1px solid ${this.itemColor}`;
    },
  },
});
