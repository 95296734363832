
// @ts-nocheck
import Vue from "vue";

import ResultCard from "@/components/ResultCard.vue";

export default Vue.extend({
  name: "RegisterInfoOther",
  components: {
    ResultCard,
  },
  props: {
    registerOther: Array,
  },
  methods: {
    getItemColor(item) {
      if (item.body === "Companies House") return "#000000";
      if (item.body === "Financial Conduct Authority") return "#711747";
      if (item.body === "HM Revenue and Customs") return "#008686";
      return null;
    },
    getItemWithIcon(item) {
      if (item.body === "Companies House")
        item.icon = "/img/logos/companies_house.png";
      if (item.body === "Financial Conduct Authority")
        item.icon = "/img/logos/fca.png";
      if (item.body === "HM Revenue and Customs")
        item.icon = "/img/logos/hmrc.png";
      return item;
    },
  },
});
