
// @ts-nocheck
import { SkeletonLine } from "@num/component-library";
import Vue from "vue";

import CDLogo from "@/components/CDLogo.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import Container from "@/components/Container.vue";

interface Data {
  hasScrolledBeyondDiv: boolean;
  stickyEl: HTMLElement | null;
}

export default Vue.extend({
  name: "CompanyHeaderSticky",
  components: {
    CDLogo,
    CompanyLogo,
    Container,
    SkeletonLine,
  },
  props: {
    companyDomain: String,
    companyLogo: String,
    companyName: String,
    companyResult: [Object, Error],
    imageResult: Object,
    loading: Boolean,
  },
  data(): Data {
    return {
      hasScrolledBeyondDiv: false,
      stickyEl: null,
    };
  },
  mounted() {
    this.stickyEl = this.$refs.sticky;
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    isTop() {
      return this.stickyEl.getBoundingClientRect().top <= 0;
    },
    onScroll() {
      this.hasScrolledBeyondDiv = this.isTop();
    },
  },
});
