
// @ts-nocheck
import { utilsTypeOf, SkeletonLine } from "@num/component-library";
import Vue from "vue";

const { isEmptyObject } = utilsTypeOf;

const twoLevelDeepTopLevelDomains = [".co.uk", ".org.uk", ".uk.com"];

export default Vue.extend({
  components: {
    SkeletonLine,
  },
  props: {
    companyDomain: String,
    companyResult: [Object, Error],
    loading: Boolean,
  },
  data() {
    return {
      isEmptyObject,
    };
  },
  computed: {
    formattedCompanyDomain() {
      if (!this.companyDomain) return null;

      const dotsInCompanyDomain = (this.companyDomain.match(/\./g) || [])
        .length;
      const companyDomainLevelsDeep = dotsInCompanyDomain + 1;
      const companyDomainHasCommonTwoLevelDeepGtld =
        twoLevelDeepTopLevelDomains.some((gtld) =>
          this.companyDomain.endsWith(gtld),
        );

      const isSafeToPrefix =
        companyDomainLevelsDeep === 2 ||
        (companyDomainHasCommonTwoLevelDeepGtld &&
          companyDomainLevelsDeep === 3);

      const mainUrl = isSafeToPrefix
        ? `www.${this.companyDomain}`
        : this.companyDomain;

      return `http://${mainUrl}`;
    },
  },
});
